/**
 * @generated SignedSource<<cddcb220b7c0c2e2421ac45927dd248d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EdsJobIngestionFormat = "EDS_JOB_INGESTION_FORMAT_CSV" | "EDS_JOB_INGESTION_FORMAT_NDJSON" | "EDS_JOB_INGESTION_FORMAT_PSV" | "EDS_JOB_INGESTION_FORMAT_TSV" | "EDS_JOB_INGESTION_FORMAT_UNKNOWN" | "EDS_JOB_INGESTION_FORMAT_XML" | "%future added value";
export type EdsJobSync = "EDS_JOB_SYNC_CUSTOM_ATTRIBUTES" | "EDS_JOB_SYNC_CUSTOM_EVENTS" | "EDS_JOB_SYNC_ECOMMERCE_ADD_TO_CARTS" | "EDS_JOB_SYNC_ECOMMERCE_PRODUCT_VIEWS" | "EDS_JOB_SYNC_ECOMMERCE_PURCHASES" | "EDS_JOB_SYNC_MOBILE_WALLET" | "EDS_JOB_SYNC_PRODUCT_CATALOG" | "EDS_JOB_SYNC_SUBSCRIBER_OPT_INS" | "EDS_JOB_SYNC_SUBSCRIBER_OPT_OUTS" | "EDS_JOB_SYNC_TEXT_SEND" | "EDS_JOB_SYNC_TEXT_SEND_BULK" | "EDS_JOB_SYNC_UNKNOWN" | "EDS_JOB_SYNC_USER_IDENTIFIERS" | "%future added value";
export type EdsJobSyncMethod = "EDS_JOB_SYNC_METHOD_AMAZON_S3" | "EDS_JOB_SYNC_METHOD_ATTENTIVE_SFTP" | "EDS_JOB_SYNC_METHOD_CLIENT_HOSTED_SFTP" | "EDS_JOB_SYNC_METHOD_MANUAL_UPLOAD" | "EDS_JOB_SYNC_METHOD_S3" | "EDS_JOB_SYNC_METHOD_SFTP" | "EDS_JOB_SYNC_METHOD_SNOWFLAKE_DATA_SHARE" | "EDS_JOB_SYNC_METHOD_UNKNOWN" | "%future added value";
export type SyncRunStatus = "SYNC_RUN_STATUS_COMPLETED_MIXED_SUCCESS" | "SYNC_RUN_STATUS_COMPLETED_SUCCESSFULLY" | "SYNC_RUN_STATUS_FAILED" | "SYNC_RUN_STATUS_RUNNING" | "SYNC_RUN_STATUS_SCHEDULED" | "SYNC_RUN_STATUS_UNKNOWN" | "%future added value";
export type SyncRunStep = "SYNC_RUN_STEP_CHUNK_FILE" | "SYNC_RUN_STEP_FILE_DOWNLOAD" | "SYNC_RUN_STEP_FILE_SURVEY" | "SYNC_RUN_STEP_INGEST_DATA" | "SYNC_RUN_STEP_PREPROCESSOR" | "SYNC_RUN_STEP_PRODUCT_CATALOG_UPLOAD" | "SYNC_RUN_STEP_SET_RATELIMIT" | "SYNC_RUN_STEP_SNOWFLAKE_CONTENT_DOWNLOAD" | "SYNC_RUN_STEP_SYNC_START" | "SYNC_RUN_STEP_TRANSFORM_DATA" | "SYNC_RUN_STEP_UNKNOWN" | "SYNC_RUN_STEP_WAITING_FOR_EXTERNAL_SYSTEM_RESULTS" | "%future added value";
export type SyncRunDetail_EDSLib_Query$variables = {
  syncId: string;
  syncRunId: string;
};
export type SyncRunDetail_EDSLib_Query$data = {
  readonly sync: {
    readonly id?: string;
    readonly name?: string;
    readonly " $fragmentSpreads": FragmentRefs<"SyncRunSummary_EDSLib_Sync">;
  } | null;
  readonly syncRun: {
    readonly updated?: SerializedDateTime;
    readonly " $fragmentSpreads": FragmentRefs<"SyncRunLinks_EDSLib_SyncRun" | "SyncRunSteps_EDSLib_SyncRunDetails" | "SyncRunSummary_EDSLib_SyncRun">;
  } | null;
};
export type SyncRunDetail_EDSLib_Query$rawResponse = {
  readonly sync: {
    readonly __typename: "Sync";
    readonly description: string;
    readonly fileType: EdsJobIngestionFormat;
    readonly id: string;
    readonly name: string;
    readonly syncMethod: EdsJobSyncMethod;
    readonly syncType: EdsJobSync;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
  readonly syncRun: {
    readonly __typename: "SyncRun";
    readonly failed: number;
    readonly id: string;
    readonly ingested: number;
    readonly status: SyncRunStatus;
    readonly syncRunDetails: {
      readonly links: {
        readonly datadog: string;
        readonly druid: string;
        readonly temporal: string;
      };
      readonly stepTroubleshootingDetails: ReadonlyArray<{
        readonly details: any;
        readonly status: SyncRunStatus;
        readonly step: SyncRunStep;
        readonly timestamp: SerializedDateTime;
      }>;
    } | null;
    readonly updated: SerializedDateTime;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type SyncRunDetail_EDSLib_Query = {
  rawResponse: SyncRunDetail_EDSLib_Query$rawResponse;
  response: SyncRunDetail_EDSLib_Query$data;
  variables: SyncRunDetail_EDSLib_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "syncId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "syncRunId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "syncId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "syncRunId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SyncRunDetail_EDSLib_Query",
    "selections": [
      {
        "alias": "sync",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SyncRunSummary_EDSLib_Sync"
              }
            ],
            "type": "Sync",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "syncRun",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SyncRunSteps_EDSLib_SyncRunDetails"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SyncRunLinks_EDSLib_SyncRun"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SyncRunSummary_EDSLib_SyncRun"
              }
            ],
            "type": "SyncRun",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SyncRunDetail_EDSLib_Query",
    "selections": [
      {
        "alias": "sync",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "syncMethod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "syncType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fileType",
                "storageKey": null
              }
            ],
            "type": "Sync",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "syncRun",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SyncRunDetails",
                "kind": "LinkedField",
                "name": "syncRunDetails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SyncRunStepTroubleshootingDetails",
                    "kind": "LinkedField",
                    "name": "stepTroubleshootingDetails",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "details",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "step",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timestamp",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SyncRunLinks",
                    "kind": "LinkedField",
                    "name": "links",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "datadog",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "druid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "temporal",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "failed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ingested",
                "storageKey": null
              }
            ],
            "type": "SyncRun",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d2283fe670a3986d68ba6c805172748",
    "id": null,
    "metadata": {},
    "name": "SyncRunDetail_EDSLib_Query",
    "operationKind": "query",
    "text": "query SyncRunDetail_EDSLib_Query(\n  $syncId: ID!\n  $syncRunId: ID!\n) {\n  sync: node(id: $syncId) {\n    __typename\n    ... on Sync {\n      id\n      name\n      ...SyncRunSummary_EDSLib_Sync\n    }\n    id\n  }\n  syncRun: node(id: $syncRunId) {\n    __typename\n    ... on SyncRun {\n      updated\n      ...SyncRunSteps_EDSLib_SyncRunDetails\n      ...SyncRunLinks_EDSLib_SyncRun\n      ...SyncRunSummary_EDSLib_SyncRun\n    }\n    id\n  }\n}\n\nfragment SyncRunLinks_EDSLib_SyncRun on SyncRun {\n  syncRunDetails {\n    links {\n      datadog\n      druid\n      temporal\n    }\n  }\n}\n\nfragment SyncRunSteps_EDSLib_SyncRunDetails on SyncRun {\n  status\n  syncRunDetails {\n    ...aggregateSyncRunSteps_EDSLib_SyncRunDetails\n  }\n}\n\nfragment SyncRunSummary_EDSLib_Sync on Sync {\n  name\n  syncMethod\n  syncType\n  description\n  fileType\n}\n\nfragment SyncRunSummary_EDSLib_SyncRun on SyncRun {\n  id\n  failed\n  ingested\n  status\n}\n\nfragment aggregateSyncRunSteps_EDSLib_SyncRunDetails on SyncRunDetails {\n  stepTroubleshootingDetails {\n    details\n    status\n    step\n    timestamp\n  }\n}\n"
  }
};
})();

(node as any).hash = "3874173bf75162751d8233867f1ef9eb";

export default node;
